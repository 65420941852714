import * as jose from 'jose';
import { LANGUAGES } from './constants';
import moment from 'moment';
require('moment/locale/zh-cn'); // Import Chinese locale
require('moment/locale/ms');

export const getAPIVersion = () => {
  return localStorage.getItem('appVersion') || '';
};

export const getAPIHost = () => {
  if (window.location.hostname !== 'localhost' && window.location.hostname !== '172.0.0.1') {
    return process.env.REACT_APP_BFF_API || `${window.location.origin}/api`;
  } else {
    return process.env.REACT_APP_BFF_API || '//localhost:3010/api';
  }
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const getAppToken = () => {
  return localStorage.getItem('appToken');
};

export const isTokenValid = (t) => {
  if (t) {
    const tk = jose.decodeJwt(t);
    if (tk?.exp && tk?.aud && tk?.iat) {
      return new Date().getTime() < tk.exp * 1000;
    }
    return false;
  }
  return false;
};

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isLocal = () => {
  return process.env.REACT_APP_ENV === 'LOCAL';
};

export const getLangRegex = () => {
  return new RegExp('^/?[a-z]{2}(?:/|$)', 'i');
};

export const removeLanguageCode = (url) => {
  let newUrl = url.replace(getLangRegex(), '');
  return newUrl.startsWith('/') ? newUrl : newUrl.length > 0 ? `/${newUrl}` : '';
};

export const getLang = () => {
  const langFromCookies = document.cookie
    .split('; ')
    .find((i) => i.includes('language'))
    ?.split('=')[1];

  const langFromPath = LANGUAGES.includes(window.location.pathname?.split('/')?.[1])
    ? window.location.pathname?.split('/')?.[1]
    : '';

  return langFromCookies || langFromPath || 'en';
};

export const buildUrl = (url, replaceLang, replaceHost) => {
  url = url && url.length > 1 ? url : '';
  const host = replaceHost ? replaceHost : window.location.hostname;
  const siteBase = '';
  // const siteBase = getSiteBasePath();
  const protocol = window.location.protocol.replace(':', '');
  const baseUrl = isLocal() ? '//localhost:3000' : `${protocol}://${host}${siteBase ? '/' + siteBase : ''}`;

  if (replaceLang) {
    const expires = new Date();
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
    document.cookie = `${'language'}=${replaceLang};expires=${expires.toUTCString()};path=/`;
    return `${baseUrl}/${replaceLang}${removeLanguageCode(url)}`;
  }

  return `${baseUrl}${url}`;
};

export const buildShareUrl = (url) => {
  const host = window.location.hostname;
  const siteBase = '';

  const protocol = window.location.protocol.replace(':', '');
  const baseUrl = isLocal() ? 'http://localhost:3000' : `${protocol}://${host}${siteBase ? '/' + siteBase : ''}`;

  return `${baseUrl}/${url}`;
};

export const randomStr = (len = 21) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

export const pathStack = (pathStack = []) => {
  let _pathStack = [...pathStack];
  return {
    push: () => {
      let url = window.location.pathname;
      if (window.location.search) url += window.location.search;
      _pathStack.push(url);
      return _pathStack;
    },
    pop: () => {
      let lastPath = _pathStack[_pathStack.length - 1];
      _pathStack.pop();
      return { lastPath, pathStack: _pathStack };
    },
  };
};

export const generateAbbreviation = (title) => {
  const words = title ? title.split(' ') : '';
  let abbreviate = '';
  if (words.length > 1) {
    abbreviate = words.map((word) => word[0]).join('');
  } else {
    abbreviate = title;
  }
  return abbreviate.slice(0, 2).toUpperCase();
};

export const generateColor = (title = '') => {
  const colorPalette = [
    '#22092C',
    '#872341',
    '#BE3144',
    '#344C64',
    '#577B8D',
    '#321E1E',
    '#116D6E',
    '#4E3636',
    '#1B1A55',
  ];
  return colorPalette[title?.length % colorPalette.length];
};

export const addSearchParamsToUrl = (url, params) => {
  // Helper function to append or update search parameters in a URL
  function addOrUpdateParams(searchParams, param) {
    if (searchParams.has(param.name)) {
      searchParams.set(param.name, param.value);
    } else {
      searchParams.append(param.name, param.value);
    }
  }
  // Parse the URL
  let urlObject = new URL(url);
  // Handle single param object or array of param objects
  if (Array.isArray(params)) {
    params.forEach((param) => addOrUpdateParams(urlObject.searchParams, param));
  } else {
    addOrUpdateParams(urlObject.searchParams, params);
  }
  // Return the updated URL as a string
  return urlObject.pathname + urlObject.search;
};

export const addParams = (url, params) => {
  let newUrl = new URL(url);
  let searchParams = new URLSearchParams(newUrl.search);

  for (const [key, value] of Object.entries(params)) {
    searchParams.set(key, value);
  }

  newUrl.search = searchParams.toString();
  return newUrl.toString();
};

//temporary simulate logged in
export const getAboId = () => {
  return localStorage.getItem('aboId');
};

/**
 * Processes program completion badges for a user.
 *
 * @param {Date} [date=moment()] - Date input.
 * @param {string} [format='YYYY-MM-DD'] - Date format.
 * @returns {string} - A formatted date.
 */
export const formatDate = (date = moment(), format = 'YYYY-MM-DD') => {
  return moment(date, ['YYYY-MM-DD', 'DD/MM/YYYY', 'YYYY/MM/DD'])
    .locale(getLang()?.replace('zh', 'zh-cn'))
    .format(format);
};

export const validateEmail = (email) => {
  if (!email) return false;
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  return emailRegex.test(email);
};

export const validatePhone = (phone) => {
  if (!phone) return false;
  const phoneRegex = /^\+\d{1,3}\d{8,14}$/;
  return phoneRegex.test(phone);
};

export const checkIsAcceptedFormat = (fileFormat, fileName = '', acceptFormat) => {
  let detectedFormat = '';
  let isFileTypeValid = acceptFormat.some((format) => {
    if (format.includes('*')) {
      // Handle wildcard (e.g., 'image/*', 'video/*')
      const [type] = format.split('/');
      const [fileType] = fileFormat.split('/');
      if (type === fileType) detectedFormat = format;
      return type === fileType;
    } else if (format.startsWith('.')) {
      // Handle file extensions (e.g., '.pdf', '.doc')
      const fileExtension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
      if (format.toLowerCase() === fileExtension) detectedFormat = format;
      return format.toLowerCase() === fileExtension;
    } else {
      // Handle exact MIME type matches (e.g., 'text/plain')
      if (format === fileFormat) detectedFormat = format;
      return format === fileFormat;
    }
  });
  return { isFileTypeValid, detectedFormat };
};

export const validateAttachmentFormat = (e, { fileSizeInMB = 10, acceptFormat = ['image/*'] }) => {
  const MAX_FILE_SIZE = fileSizeInMB * 1024 * 1024; // 20MB

  if (
    e?.target &&
    ((e?.target?.files && e?.target?.files?.length > 0) ||
      (e?.dataTransfer?.files && e?.dataTransfer?.files?.length > 0))
  ) {
    const file = e.target.files || e.dataTransfer.files;
    const isFileSizeValid = file[0].size <= MAX_FILE_SIZE; // Valid File Size
    const { isFileTypeValid } = checkIsAcceptedFormat(file[0].type, file[0].name, acceptFormat); // Valid File Type

    let message = '';
    if (!isFileSizeValid && !isFileTypeValid) {
      message = 'Invalid file type and file size exceeds the limit';
    } else if (!isFileSizeValid && isFileTypeValid) {
      message = 'File size exceeds the limit';
    } else if (isFileSizeValid && !isFileTypeValid) {
      message = 'Invalid file type';
    }
    const isFileValid = isFileSizeValid && isFileTypeValid;

    return { status: isFileValid, message };
  }
};

export const isObject = (variable) => {
  return typeof variable === 'object' && !Array.isArray(variable) && variable !== null;
};

export const convertToClassName = (classes) => {
  return Object.keys(classes)
    .filter((key) => classes[key])
    .join(' ');
};

export const replacePlaceholders = (template, values) => {
  let show = template.replace(/{{(.*?)}}/g, (_, key) => ` ${values[key.trim()] ?? ''} `);
  return show;
};

export const formatFileSize = (size) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;
  if (typeof size !== 'number') size = Number(size);
  if (isNaN(size)) return;
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }
  return `${size.toFixed(1)} ${units[unitIndex]}`;
};

export const validateProgramEndDate = (programEndDate, moduleList) => {
  if (!programEndDate || !Array.isArray(moduleList)) return true;

  const todayDate = new Date();
  const formattedProgramEndDate = new Date(programEndDate);
  const calculatedEndDate = new Date(todayDate);

  calculatedEndDate.setDate(todayDate.getDate() + moduleList.length);
  calculatedEndDate.setHours(0, 0, 0, 0);
  formattedProgramEndDate.setHours(0, 0, 0, 0);

  return calculatedEndDate <= formattedProgramEndDate;
};
