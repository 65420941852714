import React from 'react';
import DOMPurify from 'dompurify';
import svg from './svg.json';
import { replacePlaceholders } from '../../utils/helper';

const IconSVG = ({ link, name, replaceSvg = {}, ...props }) => {
  if (link) {
    return (
      <svg {...props}>
        <image width={'100%'} height={'100%'} xlinkHref={link} />
      </svg>
    );
  } else {
    if (Object.keys(replaceSvg).length > 0) {
      svg.name[name] = replacePlaceholders(svg.name[name], replaceSvg);
    }
    return (
      <span
        className="icon-svg"
        {...props}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svg.name[name]) }}
      ></span>
    );
  }
};

export default IconSVG;
