import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCaretDown } from 'react-icons/fa';
import { FaRightFromBracket, FaRightToBracket } from 'react-icons/fa6';
import { ClickAwayListener } from '@mui/material';
import { buildUrl, getLang, pathStack, randomStr } from '../utils/helper';
import LoginImage from '../assets/icon/svg/login.svg';
import BellIcon from '../assets/icon/svg/Bell.svg';
import AmwayIcon from '../assets/img/Amw_A_Icon_Black.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAmwayIdLoginUrl, getAmwayIdRegistrationUrl, getAmwayLogoutUrl } from '../utils/login';
import { clearLogin } from '../redux/actions/authAction';
import { LoadingContext } from '../components/Loading';
// import MYflag from '../assets/img/MY_Flag.png';

const Header = () => {
  const { data } = useSelector((state) => state.layout);
  const { showNotificationBadge } = useSelector((state) => state.header);
  const { isDesktop } = useSelector((state) => state.breakpoint);
  const { isLoggedIn, userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let header, commonLabel;
  if (data) {
    header = data.header;
    commonLabel = data?.commonLabel?.[0]?.labels;
  }
  const [isMenuToggle, setIsMenuToggle] = useState(false);
  const [isLanguageToggle, setIsLanguageToggle] = useState(false);
  const setLoadingStack = useContext(LoadingContext);

  const handleLogout = () => {
    setLoadingStack((prev) => [...prev, randomStr(4)]);
    dispatch(clearLogin(getAmwayLogoutUrl(), true));
  };

  return (
    <div id="navbar-wrapper" className="navbar navbar-expand-lg navbar-light mb-5 flex-lg-column">
      {isDesktop ? (
        <div className="navbar-desktop collapse navbar-collapse d-none d-lg-flex w-100" id="navbarSupportedContent">
          <Link className="main-logo" to={`/${getLang()}`}>
            {header?.group?.main_logo?.url && <img src={header.group.main_logo.url} height="35" />}
          </Link>

          <ul className="navbar-nav ml-auto">
            <div id="navbarContent" className="collapse navbar-collapse">
              <ul>
                <li className="dropdown-list">
                  <button
                    id="dropdownCountry"
                    className="nav-link dropdown-toggle"
                    onClick={() => setIsLanguageToggle((prevState) => !prevState)}
                  >
                    <span className="mr-2"> {header?.locale?.split('-')[0]?.toUpperCase()}</span>
                    <FaCaretDown size={12} />
                  </button>
                  {isLanguageToggle && (
                    <ClickAwayListener onClickAway={() => setIsLanguageToggle((prevState) => !prevState)}>
                      <ul className="dropdown-menu dropdown-country border-0 shadow">
                        {' '}
                        {/* Level two dropdown */}
                        {header?.country_list?.value?.map((item) => (
                          <li
                            key={item.key}
                            // className={`dropdown-submenu dropdown-item ${locals.country === item.key ? `country-lang-selected` : ``}`}
                          >
                            {/* <a
                            id="dropdownLang"
                            className={`dropdown-toggle ${locals.country === item.key ? `country-lang-selected` : ``}`}
                          >
                            {item.key === 'malaysia' ? <img src={MYflag} className="mr-3" /> : null}
                            <span className="mr-2">{item.value}</span>
                            <FaCaretDown size={12} color="#ffffff" />
                          </a> */}
                            <ul
                              aria-labelledby="dropdownLang"
                              className=" dropdown-submenu dropdown-menu border-0 shadow dropdownLang"
                            >
                              {header.languages_by_country.map((langMapping) => {
                                if (langMapping.country.toLowerCase() === item.key.toLowerCase()) {
                                  return (
                                    langMapping.language_list &&
                                    langMapping.language_list.value.map((lang) => (
                                      <li
                                        key={lang.key}
                                        className={`dropdown-item ${header?.locale?.split('-')[0] === lang.key.split('-')[0] ? `country-lang-selected` : ``}`}
                                      >
                                        <button
                                          className={`dropdown-option ${header?.locale?.split('-')[0] === lang.key.split('-')[0] ? `country-lang-selected` : ``}`}
                                          onClick={() => {
                                            window.location.href = buildUrl(
                                              window.location.pathname + window.location.search,
                                              lang.key.split('-')[0],
                                            );
                                          }}
                                        >
                                          {lang.value}
                                        </button>
                                      </li>
                                    ))
                                  );
                                }
                              })}
                            </ul>
                          </li>
                        ))}
                        {/* End Level two */}
                      </ul>
                    </ClickAwayListener>
                  )}
                </li>
              </ul>
            </div>

            <li className="nav-item d-flex align-items-center login-wrapper">
              <Link
                to={`/${getLang()}/faq`}
                className="nav-link"
                aria-label="go to faq"
                state={{ pathStack: pathStack(location.state?.pathStack).push() }}
              >
                {commonLabel?.faq_label}
              </Link>
              {isLoggedIn ? (
                <>
                  <Link
                    to={`/${getLang()}/notification`}
                    aria-label="go to notification"
                    state={{ pathStack: pathStack(location.state?.pathStack).push() }}
                    className="notification-bell nav-link"
                    title={commonLabel?.notification_label}
                  >
                    <img src={BellIcon} alt="Notification" height="18" />
                    {showNotificationBadge && <div className="bell-badge" />}
                  </Link>
                  <Link
                    to={`/${getLang()}/profile`}
                    aria-label="go to profile"
                    state={{ pathStack: pathStack(location.state?.pathStack).push() }}
                    className="profile-name nav-link"
                    title={commonLabel?.profile_label}
                  >
                    <img src={LoginImage} alt="Login" height="16" />
                    <span style={{ marginInlineStart: '0.5rem' }}>{userInfo?.name}</span>
                  </Link>
                  <div className="logout-container">
                    <div className="logout-link" aria-labelledby="logoutMenu">
                      <Link to={getAmwayLogoutUrl()} className="nav-link logout-link" onClick={() => handleLogout()}>
                        {header?.labels?.logout_label}
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <div className="nav-link">
                  <img src={LoginImage} alt="Login" height="16" />
                  <Link
                    className="login-register"
                    style={{ marginInlineStart: '0.5rem' }}
                    to={getAmwayIdLoginUrl({ redirect_url: location.pathname + location.search })}
                  >
                    {header?.labels?.login_label}
                  </Link>
                  <span style={{ margin: '0.25rem' }}>/</span>
                  <Link className="login-register" to={getAmwayIdRegistrationUrl()}>
                    {header?.labels?.register_label?.title}
                  </Link>
                </div>
              )}
            </li>
          </ul>
        </div>
      ) : (
        <div className="navbar-mobile">
          <a className="main-logo" href="/">
            <img src={header?.group?.main_logo?.url ? header.group.main_logo.url : AmwayIcon} alt="Amway" height="24" />
          </a>

          <ul className="navbar-nav ml-auto d-flex flex-row align-items-center">
            {isLoggedIn && (
              <li className="nav-item pr-3">
                <div
                  className="nav-link profile-btn"
                  role="button"
                  title="Profile"
                  aria-label="go to profile"
                  onClick={() =>
                    navigate(`/${getLang()}/profile`, {
                      state: { pathStack: pathStack(location.state?.pathStack).push() },
                    })
                  }
                >
                  <img src={LoginImage} alt="Login" height="20" />
                </div>

                <div
                  className="nav-link notification-bell"
                  role="button"
                  title="Notification"
                  aria-label="go to notification"
                  onClick={() =>
                    navigate(`/${getLang()}/notification`, {
                      state: { pathStack: pathStack(location.state?.pathStack).push() },
                    })
                  }
                >
                  <img src={BellIcon} alt="Bell" height="23" />
                  {showNotificationBadge && <div className="bell-badge" />}
                </div>
              </li>
            )}
            <li className="nav-item position-relative" onClick={() => setIsMenuToggle((prevState) => !prevState)}>
              <div className={`burger-menu ${isMenuToggle ? 'active' : ''}`}>
                <div className="bar top"></div>
                <div className="bar middle"></div>
                <div className="bar bottom"></div>
              </div>
            </li>
          </ul>

          <div className={`navbar-content ${isMenuToggle ? 'active' : ''}`}>
            <div className="nav-accordion" id="navAccordionCountry">
              <div className="collapse">
                <div className="accordion-wrapper-body">
                  <ul className="nav-accordion-list">
                    {header?.languages_by_country.map((langMapping) => {
                      return (
                        langMapping.language_list &&
                        langMapping.language_list.value.map((lang, index) => (
                          <li className="nav-accordion-list-item pb-1" key={index}>
                            <a
                              tabIndex={-1}
                              className={`p-2 ${header?.locale?.split('-')[0] === lang.key.split('-')[0] ? 'country-lang-selected' : ''}`}
                              onClick={() => {
                                window.location.href = buildUrl(
                                  window.location.pathname + window.location.search,
                                  lang.key.split('-')[0],
                                );
                              }}
                            >
                              {lang.value}
                            </a>
                          </li>
                        ))
                      );
                    })}
                  </ul>
                </div>
              </div>
              <hr className="divider" />
              <div className="navbar-bottom">
                <Link
                  to={`/${getLang()}/faq`}
                  style={{ color: '#2c2c2c' }}
                  state={{ pathStack: pathStack(location.state?.pathStack).push() }}
                  reloadDocument
                >
                  {commonLabel?.faq_label}
                </Link>
                {isLoggedIn ? (
                  <div className="logout-container">
                    <div className="logout-link" aria-labelledby="logoutMenu">
                      <div className="nav-link logout-link header-mobile-logout" onClick={() => handleLogout()}>
                        <FaRightFromBracket />
                        <span>{header?.labels?.logout_label}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <a className="nav-link header-mobile-logout" href={getAmwayIdLoginUrl()}>
                      <FaRightToBracket />
                      <span>{header?.labels?.login_label}</span>
                    </a>
                    <a className="nav-link header-mobile-logout" href={getAmwayIdRegistrationUrl()}>
                      <FaRightToBracket />
                      <span>{header?.labels?.register_label?.title}</span>
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
