import React, { useEffect } from 'react';
import notFoundImg from '../../assets/icon/svg/404.svg';
import { useSelector } from 'react-redux';
import './index.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getLang } from '../../utils/helper';
import { Button } from '../../app-components/Button';
import { useNotFound } from '../../hooks';
import { Loading } from '../Loading';
import { getAmwayIdLoginUrl } from '../../utils/login';

const NotFound = () => {
  const navigate = useNavigate();
  const setNotFound = useNotFound();
  const { data } = useSelector((state) => state.layout);
  const [searchParams] = useSearchParams();
  const emailRedirect = searchParams.get('email_redirect'); //used to redirect user if not login
  const { isLoggedIn } = useSelector((state) => state.auth);

  let commonLabel = {};
  if (data) commonLabel = data?.commonLabel?.[0]?.labels;

  useEffect(() => {
    if (emailRedirect === 'true' && !isLoggedIn) {
      const url = new URL(location.href);
      url.searchParams.delete('email_redirect');
      window.location.href = getAmwayIdLoginUrl({ redirect_url: url.pathname + url.search });
    }
  }, [emailRedirect, isLoggedIn]);

  if (emailRedirect === 'true' && !isLoggedIn) return <Loading />;
  return (
    <div className="not-found">
      <img src={notFoundImg} alt="404 not found" />
      <h1>{commonLabel?.page_not_found_label}</h1>
      <p>{commonLabel?.page_not_found_description}</p>
      <Button
        label={commonLabel?.back_to_home_label}
        color="#32bfaf"
        onClick={() => {
          setNotFound(false);
          navigate(`/${getLang()}`);
        }}
      />
    </div>
  );
};

export default NotFound;
