import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { InputAdornment, TextField } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BsExclamationSquare } from 'react-icons/bs';
import { FaRegCheckCircle } from 'react-icons/fa';
import { FaRegCalendar } from 'react-icons/fa';
import dayjs from 'dayjs';
import 'dayjs/locale/ms';
import 'dayjs/locale/zh-cn';
import { getLang } from '../../utils/helper';
import Drawer from '../Drawer/Drawer';
import './index.scss';

const Input = ({
  label,
  value,
  defaultValue,
  errorMessage,
  validMessage,
  inputType,
  onDateChange = () => {},
  minDate,
  maxDate,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectingMonth, setSelectingMonth] = useState(false);

  const { data } = useSelector((state) => state.layout);
  const commonLabel = data?.commonLabel?.[0]?.labels || {};

  const handleDateChange = (date) => {
    if (!selectingMonth && date) {
      setSelectedDate(date);
      setDrawerOpen(false);
      if (onDateChange) onDateChange(date.format('DD/MM/YYYY'));
    }
  };

  const handleYearAndMonthDateChange = (date) => {
    if (date) {
      if (minDate && dayjs(date).isBefore(dayjs(minDate)) && dayjs(date).isAfter(dayjs(defaultValue))) {
        setSelectedDate(dayjs(minDate));
        onDateChange(dayjs(minDate).format('DD/MM/YYYY'));
      } else if (maxDate && dayjs(date).isAfter(dayjs(maxDate))) {
        setSelectedDate(dayjs(maxDate));
        onDateChange(dayjs(maxDate).format('DD/MM/YYYY'));
      }
    } else setSelectedDate(null);
  };

  const handleViewChange = (view) => {
    if (view === 'day') {
      setSelectingMonth(false);
    } else if (view === 'year' || view === 'month') {
      setSelectingMonth(true);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <div className="input-field">
      {inputType === 'date' ? (
        <>
          <TextField
            label={label}
            variant="filled"
            value={selectedDate ? selectedDate.format('DD/MM/YYYY') : value ? dayjs(value).format('DD/MM/YYYY') : ''}
            onClick={props?.disabled ? null : toggleDrawer(true)}
            InputProps={{
              readOnly: true,
              endAdornment: props?.disabled ? null : (
                <InputAdornment position="end">
                  <FaRegCalendar color={'#2C2C2C'} />
                </InputAdornment>
              ),
            }}
            className="date-picker"
            {...props}
          />
          <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer(false)} style={{ height: 'auto' }}>
            <div className="drawer-title">
              <div>
                {label === `${commonLabel?.program_start_date_label}*` ||
                label === `${commonLabel?.select_start_date_label}`
                  ? commonLabel?.select_start_date_label
                  : commonLabel?.select_end_date_label}
              </div>
              <div className="drawer-burger-menu active" onClick={toggleDrawer(false)}>
                <div className="bar top"></div>
                <div className="bar middle"></div>
                <div className="bar bottom"></div>
              </div>
            </div>
            <div className="divider" />
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={getLang() === 'ms' ? 'ms' : getLang() === 'zh' ? 'zh-cn' : 'en'}
            >
              <DateCalendar
                value={selectedDate ?? dayjs(value)}
                onChange={handleDateChange}
                onYearChange={handleYearAndMonthDateChange}
                onMonthChange={handleYearAndMonthDateChange}
                onViewChange={handleViewChange}
                style={{ padding: '16px 0' }}
                views={['year', 'month', 'day']}
                minDate={minDate ? dayjs(minDate) : undefined}
                maxDate={maxDate ? dayjs(maxDate) : undefined}
                className="input-field-date-calendar"
              />
            </LocalizationProvider>
          </Drawer>
        </>
      ) : (
        <TextField
          label={label}
          value={value ? value : ''}
          variant="filled"
          error={!!errorMessage}
          helperText={
            <div className="input-field-helper-text">
              {errorMessage ? (
                <>
                  <BsExclamationSquare size={'13px'} />
                  <div>{errorMessage}</div>
                </>
              ) : validMessage ? (
                <>
                  {validMessage !== ' ' && <FaRegCheckCircle size={'13px'} />}
                  <div>{validMessage}</div>
                </>
              ) : (
                ''
              )}
            </div>
          }
          InputProps={{
            disableUnderline: true,
          }}
          className={validMessage ? 'valid' : ''}
          {...props}
        />
      )}
    </div>
  );
};

export default Input;
