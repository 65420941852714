import axios from 'axios';
import { useCallback } from 'react';

export const useTeamShareCount = () => {
  const handleTeamShareCount = useCallback(
    async (teamId, buttonId, action, teamCode, url, invitationCode, invitationUrl) => {
      if (action) {
        //payload
        const payload = {
          teamId: teamId,
          buttonId: buttonId,
          action: action,
          teamCode: teamCode,
          url: url,
          invitationCode: invitationCode,
          invitationUrl: invitationUrl,
        };

        const response = await axios.post(`/utils/postTeamShareCount`, payload);
        return response.data;
      } else {
        //Throw error
        let errorMessage = 'Missing action data for team share count';
        throw errorMessage;
      }
    },
    [],
  );

  return { handleTeamShareCount };
};
