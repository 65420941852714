import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { MainLayout } from '../../app-components';
import { PageLoading } from '../../components/PageLoading';
import Landing from '../Landing';
import { useSelector } from 'react-redux';

// import page component in lazy
const ProgramList = lazy(() => import('./programList'));
const MyProgramList = lazy(() => import('./myProgramList'));
const ProgramDetails = lazy(() => import('./programDetails'));
const AvailablePrograms = lazy(() => import('./availablePrograms'));
const JoinProgram = lazy(() => import('./joinProgram'));
const SelectTeam = lazy(() => import('./joinProgram/teamMember'));
const TeamSettings = lazy(() => import('./joinProgram/teamLeader'));
const MemberList = lazy(() => import('./joinProgram/teamMemberList'));
const MemberProgress = lazy(() => import('./joinProgram/teamMemberProgress'));
const Profile = lazy(() => import('./profile'));
const ProfileSettings = lazy(() => import('./profile/settings'));
const Faq = lazy(() => import('./faq'));
// const PrivacyPolicy = lazy(() => import('./privacyPolicy'));
const ModuleLecture = lazy(() => import('./moduleLecture'));
const ModuleAssignment = lazy(() => import('./moduleAssignment'));
const ModuleQuiz = lazy(() => import('./moduleQuiz'));
const ModuleQuizReview = lazy(() => import('./moduleQuizReview'));
const Notification = lazy(() => import('./notification'));
const Leaderboard = lazy(() => import('./leaderboard'));
const ProgramCertificate = lazy(() => import('./programCertificate'));
const TermsOfUse = lazy(() => import('./termsOfUse'));
const MyProgramTeamList = lazy(() => import('./myProgramTeamList'));
const WeeklyCheckInCard = lazy(() => import('./weeklyCheckInCard'));
const WeeklyCheckInReward = lazy(() => import('./weeklyCheckInReward'));
const WeeklyCheckInSpecial = lazy(() => import('./weeklyCheckInSpecial'));
const TeamInvitation = lazy(() => import('./teamInvitation'));
const MyBadges = lazy(() => import('./myBadges'));
const BadgeDetails = lazy(() => import('./badgeDetails'));
const RewardList = lazy(() => import('./rewardList'));
const RewardDetails = lazy(() => import('./rewardDetails'));
const RewardHistory = lazy(() => import('./rewardHistory'));
const MyPoints = lazy(() => import('./MyPoints'));
const PointsTransaction = lazy(() => import('./pointsTransaction'));
const PointsInformation = lazy(() => import('./pointsInformation'));
const MyFramesList = lazy(() => import('./myFramesList'));

const Home = () => {
  const { isSet, isLoggedIn, isLoggingOut } = useSelector((state) => state.auth);
  return (
    <MainLayout>
      <Suspense fallback={<PageLoading />}>
        <Routes>
          <Route path="/" element={<ProgramList />} />
          <Route path="/available-program" element={<AvailablePrograms />} />
          <Route path="/program/:program/" element={<ProgramDetails />} />
          <Route path="/program/:program/certificate" element={<ProgramCertificate />} />
          <Route path="/program/team-invitation" element={<TeamInvitation />} />
          <Route path="/faq" element={<Faq />} />
          {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
          <Route path="/terms-and-conditions" element={<TermsOfUse />} />
          {(isLoggedIn || isLoggingOut) && (
            <>
              <Route path="/my-program" element={<MyProgramList />} />
              <Route path="/my-program/:program" element={<MyProgramTeamList />} />
              <Route path="/program/:program/team-settings/:teamId" element={<TeamSettings />} />
              <Route path="/program/:program/leaderboard/:teamId" element={<Leaderboard />} />
              <Route path="/program/:program/module/:module" element={<ModuleLecture />} />
              <Route path="/program/:program/module/:module/assignment" element={<ModuleAssignment />} />
              <Route path="/program/:program/module/:module/quiz" element={<ModuleQuiz />} />
              <Route path="/program/:program/quiz-review" element={<ModuleQuizReview />} />
              <Route path="/join-program/:program/" element={<JoinProgram />} />
              <Route path="/join-program/:program/team-member" element={<SelectTeam />} />
              <Route path="/join-program/:program/team-leader" element={<TeamSettings />} />
              <Route path="/join-program/:program/member-list" element={<MemberList />} />
              <Route path="/join-program/:program/member-progress" element={<MemberProgress />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/settings" element={<ProfileSettings />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/weekly-checkin" element={<WeeklyCheckInCard />} />
              <Route path="/weekly-checkin/reward" element={<WeeklyCheckInReward />} />
              <Route path="/weekly-checkin/ecard" element={<WeeklyCheckInSpecial />} />
              <Route path="/badge/my-badges" element={<MyBadges />} />
              <Route path="/badge/badge-details/:badge-id" element={<BadgeDetails />} />
              <Route path="/reward" element={<RewardList />} />
              <Route path="/reward/details/:reward-id" element={<RewardDetails />} />
              <Route path="/reward/history" element={<RewardHistory />} />
              <Route path="/point/my-points" element={<MyPoints />} />
              <Route path="/point/my-points/transaction" element={<PointsTransaction />} />
              <Route path="/point/information" element={<PointsInformation />} />
              <Route path="/frame/my-frames-list" element={<MyFramesList />} />
            </>
          )}
          {isSet && <Route path="*" element={<Landing isNotFound={isSet} />} />}
        </Routes>
      </Suspense>
    </MainLayout>
  );
};

export default Home;
