import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';
import DrawerForm from '../../../components/Drawer/DrawerForm';
import TextField from '../../../components/Input';
import { getLang, validateEmail, pathStack } from '../../../utils/helper';
import { trackOnRegisterComplete, trackOnRegisterStart } from '../../../utils/analytics';
import './index.scss';

const ProfileInfo = ({ account, showProfileInfoDrawer, login = () => {} }) => {
  const [input, setInput] = useState({
    name: '',
    email: '',
    terms: false,
  });
  const [emailValid, setEmailValid] = useState();
  const [stepIndex, setStepIndex] = useState(0);

  const { data } = useSelector((state) => state.layout);
  let commonLabel = {};
  if (data) commonLabel = data?.commonLabel?.[0]?.labels;

  useEffect(() => {
    if (account) {
      trackOnRegisterStart();
      setInput((prev) => {
        let email = '';
        if (!account.acct_type && !account.acct_subtype) {
          if (typeof account.username === 'string') {
            email = validateEmail(account.username) ? account.username : '';
          }
        }
        return { ...prev, name: account?.abo_name, email: account?.email || email };
      });
    }
  }, [account]);

  useEffect(() => {
    setEmailValid(validateEmail(input.email));
  }, [input]);

  const handleOnChange = (value, name) => {
    const newInput = { ...input, [name]: value };
    setInput(newInput);
  };

  const handleNextStep = () => {
    setStepIndex((prev) => ++prev);
  };

  const handleSubmit = (enableEmailNoti) => {
    trackOnRegisterComplete();
    // { acc: selectedAcc, name: profileInfo?.name, email: profileInfo?.email }
    login({ acc: account, name: input?.name, email: input?.email, enableEmailNoti });
  };

  const formView = [
    {
      title: commonLabel?.tell_us_about_yourself_label,
      primaryButtonLabel: commonLabel?.next_label,
      primaryButtonOnClick: handleNextStep,
      primaryButtonDisabled: !input?.name || !input?.email || !input?.terms || !emailValid,
      content: (
        <>
          <TextField
            id="name"
            label={`${commonLabel?.name_label}*`}
            value={input?.name}
            onChange={(e) => handleOnChange(e?.target?.value, 'name')}
          />
          <TextField
            id="email"
            label={`${commonLabel?.email_label}*`}
            value={input?.email}
            onChange={(e) => handleOnChange(e?.target?.value, 'email')}
            errorMessage={input?.email && !emailValid && commonLabel?.invalid_email_label}
            validMessage={input?.email && emailValid && ' '}
          />
          <p className="profile-info-email">{commonLabel?.update_email_drawer_description} </p>
          <div className="profile-info-terms">
            <Checkbox
              id="terms"
              className="checkbox"
              onChange={(e) => handleOnChange(e?.target?.checked, 'terms')}
              style={{
                color: '#32BFAF',
              }}
            />
            {commonLabel?.terms_of_use_description_label}{' '}
            <Link
              to={`/${getLang()}/terms-and-conditions`}
              state={{ pathStack: pathStack(location.state?.pathStack).push() }}
            >
              {commonLabel?.terms_of_use_label}
            </Link>
            .
          </div>
        </>
      ),
    },
    {
      title: commonLabel?.email_notification_title,
      primaryButtonLabel: commonLabel?.enable_notifications_label,
      primaryButtonOnClick: () => handleSubmit(true),
      secondaryButtonLabel: commonLabel?.skip_label,
      secondaryButtonOnClick: () => handleSubmit(false),
      content: (
        <div className="profile-info-email-notification">{commonLabel?.email_notification_first_login_description}</div>
      ),
    },
  ];

  return (
    <DrawerForm
      open={showProfileInfoDrawer}
      title={formView[stepIndex].title}
      primaryButtonLabel={formView[stepIndex].primaryButtonLabel}
      primaryButtonOnClick={formView[stepIndex].primaryButtonOnClick}
      primaryButtonDisabled={formView[stepIndex].primaryButtonDisabled}
      secondaryButtonLabel={formView[stepIndex].secondaryButtonLabel}
      secondaryButtonOnClick={formView[stepIndex].secondaryButtonOnClick}
      hideCloseButton={true}
    >
      <div className="profile-info-drawer">{formView[stepIndex].content}</div>
    </DrawerForm>
  );
};

export default ProfileInfo;
